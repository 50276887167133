import { watch } from 'vue'
import { type UiFloatingIcon } from '@ui/components/UiFloatingIcons/UiFloatingIcons.types'
import useConditionalRender from '@base/composables/useConditionalRender/useConditionalRender'
import { T3FloatingIconType } from '../../types/FloatingIcons.types'
import type { T3FloatingIcon } from '../../types/FloatingIcons.types'

/**
 * Some of floating icons need different functionalities or behaviour than intended, so
 * we need handle those specific cases (i.e. passing action handler)
 */
async function mapFloatingIcons(floatingIcons: T3FloatingIcon[]): Promise<UiFloatingIcon[]> {
  if (!floatingIcons || !Array.isArray(floatingIcons)) {
    return []
  }

  const { localePath } = useT3Utils()
  const elements = JSON.parse(JSON.stringify(floatingIcons))

  const contactElementIndex = elements.findIndex(
    (elem: T3FloatingIcon) =>
      elem.floating_icon_type === T3FloatingIconType.CONTACT,
  )

  if (contactElementIndex !== -1) {
    elements[contactElementIndex].link = localePath('account/contact')
  }

  const reportElementIndex = elements.findIndex(
    (elem: T3FloatingIcon) =>
      elem.floating_icon_type === T3FloatingIconType.REPORT,
  )

  const { runWithContext } = useNuxtApp()
  const { isEcommerceEnabled } = useConditionalRender()
  if (isEcommerceEnabled.value && reportElementIndex !== -1) {
    await Promise.all([
      import('../../../rawlplug-ecom/composables/useStoreConfig/useStoreConfig'),
      import('../../../rawlplug-ecom/components/AppReportErrorModal/useReportErrorModal'),
    ]).then(([{ useStoreConfig }, { useReportErrorModal }]) => {
      runWithContext(() => {
        const { storeConfigData } = useStoreConfig()
        const { toggleReportErrorModal } = useReportErrorModal()
        elements[reportElementIndex].link = ''
        elements[reportElementIndex].action = toggleReportErrorModal
        elements[reportElementIndex].visible = storeConfigData.value?.enableReportErrorModal
      })
    }).catch((error) => {
      console.error('Failed to load the module:', error)
    })
  }
  else if (!isEcommerceEnabled.value && reportElementIndex !== -1) {
    elements.splice(reportElementIndex, 1)
  }

  return elements
}

export function useFloatingIcons() {
  const { initialData } = useT3Api()

  const floatingIcons = ref<Array<UiFloatingIcon>>([])

  watch(initialData, async (value) => {
    floatingIcons.value = await mapFloatingIcons(value?.floatingIcons)
  }, { immediate: true })

  return { floatingIcons }
}
