export interface T3FloatingIcon {
  icon: string
  link: string
  title?: string
  target?: string
  floating_icon_type?: string
}

export enum T3FloatingIconType {
  SOCIAL = 'social-link',
  CONTACT = 'contact-section',
  HELP = 'help-section',
  REPORT = 'report-issue-section',
}
